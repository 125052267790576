<template>
  <div class="mainContainer">
    <div class="container">
      <div class="mt-5 col-12">
        <VueSlickCarousel :arrows="true" v-bind="settings">
          <div
            v-for="item in items"
            :key="item.id"
            class="d-flex justify-content-center"
          >
            <router-link tag="a" :to="item.router">
              <img
                class="mx-auto"
                :src="require(`@/assets/image/banner/${item.img}`)"
                :alt="`${item.img}`"
              />
            </router-link>
          </div>
        </VueSlickCarousel>
      </div>
      <div class="mt-5">
        <div class="containerRequest">
          <h2>
            Pedido <strong> # {{ getpedidos.id }} </strong>
          </h2>
        </div>
        <transition name="fade">
          <component :is="getCpCurrent" :itemRequest.sync="getpedidos" />
        </transition>
        <hr />
        <div class="col-12 d-flex align-items-center justify-content-between">
          <div class="buttonsContainer">
            <button @click="discard">
              <img src="@/assets/image/pedidos/delete.png" alt="delete" />
              Descartar Pedido
            </button>
            <button @click="manterPedido()">
              <img src="@/assets/image/pedidos/guardar.png" alt="guardar" />
              Guardar Pedido
            </button>
          </div>
          <button class="btnFinish" @click="finishService" :disabled="loadSend">
            <div v-if="!loadSend">
              <img
                src="@/assets/image/pedidos/finalizar.png"
                alt="finalizar"
                class="pr-2"
              />
              {{
                getCpCurrent === "checkService"
                  ? "Finalizar Pedido"
                  : "Concluir Pedido"
              }}
            </div>

            <half-circle-spinner
              :animation-duration="1000"
              :size="30"
              color="#fff"
              v-else
            />
          </button>
        </div>
        <div class="p-3" v-if="getCpCurrent === 'finishedPedidos'">
          <button class="backPage" @click="backPage">Voltar</button>
        </div>
      </div>
    </div>
    <modalDiscard />
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { HalfCircleSpinner } from "epic-spinners";
import { mapGetters, mapMutations, mapActions } from "vuex";
import HTTP from "@/api_system";
export default {
  components: {
    checkService: () =>
      import("@/components/finalizar_pedidos/checkService.vue"),
    finishedPedidos: () =>
      import("@/components/finalizar_pedidos/finishedPedidos.vue"),
    modalDiscard: () => import("@/components/finalizar_pedidos/discard"),
    VueSlickCarousel,
    HalfCircleSpinner
  },
  computed: {
    ...mapGetters([
      "getpedidos",
      "payValueGet",
      "clientDto",
      "cpfUserUndefined",
      "getStateWatch",
      "user",
      "getCpCurrent",
      "getOrderServiceId",
      "getServiceDespachanteStatus",
      "get_message"
    ])
  },
  data() {
    return {
      settings: {
        autoplay: false
      },
      loadSend: false,
      items: [
        {
          id: 1,
          img: "banner1.png",
          router: "/servico/1214"
        },
        {
          id: 1,
          img: "banner2.png",
          router: "/servico/1214"
        },
        // {
        //   id: 2,
        //   img: "capa.png",
        //   router: "/servico/1214"
        // },
        // {
        //   id: 2,
        //   img: "banner2.png",
        //   router: '/finalizar-servico'
        // }
      ],
      valuesPays: [],
      getpedidosCurrent: {}
    };
  },
  beforeRouteLeave(to, from, next) {
    this.setCpCurrent("checkService");
    next();
  },
  methods: {
    ...mapMutations([
      "newDiscard",
      "setStateWatch",
      "payValue",
      "setCpCurrent",
      "set_pedido",
      "setServiceDespachanteStatus"
    ]),
    ...mapActions(["sendPedidos", "sendDespanchanteFinish"]),

    async sendUpdatePedido() {
      try {
        const result = await HTTP.post(
          `services/app/OrdersServices/ResultOfApplyOrderService?orderId=${this.$store.state.pedidos.pedidos.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }
        );
        this.set_pedido(result.data.result);
        return;
      } catch (error) {
        // 404 limpa o set pedido e redi
        // 500 a mesma coisa
        console.error(error);
        if (error.response.data.error.code === 302) {
          this.toast(error.response.data.error.message, this.$toast.error);
          this.redirectPageError();
          return;
        }

        if (error.response.data.error.code === 100) {
          this.toast(error.response.data.error.message, this.$toast.error);
          this.redirectPageError();
          return;
        }
        this.toast("Ops algo deu errado", this.$toast.error);
      }
    },
    redirectPageError() {
      this.$store.commit("changeOrderId", "");
      setTimeout(() => {
        this.$store.commit("set_pedido", "");
        this.$router.push("/pedidos");
      }, 2000);
    },
    finishService() {
      if (this.getCpCurrent === "checkService") {
        this.setCpCurrent("finishedPedidos");
        return;
      }
      this.sendRequest();
    },

    sendRequest() {
      this.loadSend = true;
      if (this.cpfUserUndefined === false && !this.user) {
        this.toast(
          "Antes de concluir, preencha o campo CPF cliente ou clique na opção acima",
          this.$toast.error
        );
        this.loadSend = false;
        return;
      }

      if (this.getStateWatch === false) {
        this.setStateWatch(true); // atualizar meio de pagamento
      } else {
        this.setStateWatch(true); // atualizar meio de pagamento
      }

      if (this.cpfUserUndefined) {
        this.$store.commit("userCliente", "");
      }

      setTimeout(() => {
        if (this.payValueGet === false && this.getpedidos.total > 0) {
          this.loadSend = false;
          this.toast(
            "Valor informado é menor que o valor do pedido!",
            this.$toast.error
          );
          return;
        }
        if (this.getpedidos.total <= 0) {
          for (let i = 0; i <= 2; i++) {
            let value = {
              typePayment: i,
              valuePayment: Number(this.getpedidos.total)
            };
            this.valuesPays.push(value);
          }
          this.payValue(this.valuesPays);
        }
        this.request();
      }, 1000);
    },

    despanchanteFinish() {
      this.sendDespanchanteFinish()
        .then(res => {
          console.log(res);
          this.setServiceDespachanteStatus(false);
        })
        .catch(err => {
          console.log(err);
        });
    },

    request() {
      this.$store
        .dispatch("finishPedido", this.getpedidos.id)
        .then(resolve => {
          if (this.getServiceDespachanteStatus) {
            // this.despanchanteFinish();
          }

          this.toast("Pedido finalizado com Sucesso!.", this.$toast.success);
          setTimeout(() => {
            this.$store.commit("payValue", false); //! refatorar
            this.$store.commit("changeOrderId", "");
            this.$store.commit("cpfUndefined", false);
            this.$store.commit("userCliente", "");
            this.$store.commit("set_pedido", "");
            this.$router.push("/pedidos");
          }, 2000);
        })
        .catch(error => {
          // this.toast('Ops', this.$toast.error)
          this.loadSend = false;
          this.$store.commit("changeOrderId", "");
          this.toast(error.response.data.error.message, this.$toast.error);
          console.log(error);

          setTimeout(() => {
            this.$store.commit("set_pedido", "");
            this.$router.push("/pedidos");
          }, 2000);

          // this.toast('Valor maior que o pedido. Para pagar com mais de um meio, não deve haver troco.', this.$toast.error)
        });
    },

    backPage() {
      this.setCpCurrent("checkService");
      this.$store.commit("cpfUndefined", false);
      this.setStateWatch(false);
      this.payValue(false);
    },

    manterPedido() {
      this.toast("Pedido Salvo!", this.$toast);
      setTimeout(() => {
        this.$store.commit("set_pedido", "");
        this.$store.commit("changeOrderId", "");
        this.$router.push("/pedidos");
      }, 2000);
    },

    discard() {
      const json = {
        title: "Deseja descartar este Pedido?",
        id: this.getpedidos.id,
        service: false,
        url: `/services/app/Order/DiscardOrder?orderId=${this.getpedidos.id}&MessageCancelation`
      };
      this.newDiscard(json);
      setTimeout(() => {
        $("#modal_discard").modal("show");
      }, 50);
    }
  },
  watch: {
    get_message: function() {
      if (this.get_message == 9 && this.$store.state.pedidos.pedidos.id) {
        this.sendUpdatePedido();
      }
    }
  }
};
</script>

<style scoped>
* {
  font-style: normal;
  font-weight: 700;
}

.mainContainer {
  margin-bottom: 40px;
}
.containerRequest {
  border-bottom: 1px solid #3f88c1;
}

.containerRequest h2 {
  font-size: 24px;
  color: #98c4e6;
}
.containerRequest h2 strong {
  color: #fff;
}

hr {
  height: 2px;
  border-radius: 5px;
  background: #3f88c1;
}

.buttonsContainer button {
  background: none;
  border: none;
  color: #fff;
  outline: none;
  transition: all 0.2s linear;
  padding: 10px;
  border-radius: 6px;
  margin-right: 20px;
  height: 40px;
}

.buttonsContainer button:hover {
  background: #3f88c1;
}

.btnFinish {
  background: #25d366;
  border-radius: 6px;
  border: none;
  color: #fff;
  height: 40px;
  padding: 0 20px;
  outline: none;
  transition: all 0.2s linear;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnFinish:hover {
  background: #13ad4c;
}

.backPage {
  background: none;
  color: #fff;
  border: none;
  outline: none;
  height: 40px;
  padding: 0 20px;
  transition: all 0.2s linear;
  border-radius: 10px;
}
.backPage:hover {
  background: #3f88c1;
}
</style>
